import { ApolloError, gql, useApolloClient } from "@apollo/client";
import { useSnackbar } from "notistack";
import { useContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useParams } from "react-router-dom";
import localized from "../../../en.json";
import { BreadCrumbContext } from "../../../store/breadcrumb-context";

import { Card, CardContent, Stack, Typography } from "@mui/material";
import { SiteDetail } from "../../../Models/models";
import ShowSnackbar from "../../CustomizedSnackbar/ShowSnackbar";
import { AssetEdgeMapping } from "./AssetEdgeMapping/AssetEdgeMapping";
import EdgeDevices from "./EdgeDevices/EdgeDevices";
import { CreateAsset } from "./EnergyAssets/CreateNewAsset/CreateAsset";
import { EnergyAssets } from "./EnergyAssets/EnergyAssets";
import { SiteDetails } from "./SiteDetails/SiteDetails";
import { SiteMenuList } from "./SiteMenuList/SiteMenuList";

export const GET_SITE_BY_ID = gql`
  query ($siteID: Int!) {
    getSiteById(siteID: $siteID) {
      id
      name
      description
      createdAt
      updatedAt
      latitude
      longitude
      altitude
      project {
        id
        name
      }
      assets {
        id
      }
      devices {
        id
      }
    }
  }
`;
export const SiteManagement = () => {
  const [siteDetails, setSiteDetails] = useState<SiteDetail>();
  const client = useApolloClient();

  const { siteId } = useParams();
  const { deviceId } = useParams();
  const {protocolId} =useParams();
  const {protocolConfigId} = useParams();
  const location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [isSiteEdited, setIsSiteEdited] = useState(false);

  const breadCrumbContext = useContext(BreadCrumbContext);
  useEffect(() => {
    const breadcrumbs = [
      {
        name: "Engineering",
        link: "/engineering/customer",
      },
      {
        name: siteDetails?.project.name,
        link: `/engineering/project/${siteDetails?.project.id}/sites`,
      },
      {
        name: siteDetails?.name,
        link: `/engineering/site/${siteId}/energyassets`,
      },
    ];

    if (location.pathname.includes("createAsset")) {
      breadcrumbs.push({
        name: "New Asset",
        link: `/engineering/site/${siteId}/energyassets/createAsset`,
      });
    }
    if (location.pathname.includes("newprotocol")) {
      breadcrumbs.push({
        name: "New protocol",
        link: `/engineering/site/${siteId}/edgedevices/device/${deviceId}/newprotocol`,
      },);
    }
    if (location.pathname.includes("editprotocol")) {
      breadcrumbs.push({
        name: "Edit protocol",
        link: `/engineering/site/${siteId}/edgedevices/device/${deviceId}/editprotocol/${protocolId}/${protocolConfigId}`,
      },);
    }

    breadCrumbContext?.setBreadCrumbsArray(breadcrumbs);
  }, [
    breadCrumbContext?.setBreadCrumbsArray,
    location.pathname,
    siteDetails?.project,
  ]);

  const getSiteById = () => {
    client
      .query({
        query: GET_SITE_BY_ID,
        variables: {
          siteID: siteId,
        },
        fetchPolicy: "no-cache",
      })
      .then((response: any) => {
        setSiteDetails(response.data.getSiteById);
      })
      .catch((error: ApolloError) => {
        ShowSnackbar(
          localized["failed-to-fetch-site-details"],
          false,
          enqueueSnackbar
        );
      });
  };
  useEffect(() => {
    getSiteById();
    setIsSiteEdited(false);
  }, [siteId, isSiteEdited, location]);
  return (
    <Stack
      width={"97%"}
      direction={"row"}
      gap={"30px"}
      height="calc(100vh - 155px)"
      marginLeft={"32px"}
      marginTop={"24px"}
    >
      <Stack>
        <Typography
          variant="h4"
          lineHeight={"34px"}
          marginBottom={"40px"}
          sx={{ maxWidth: "315px", wordBreak: "break-word" }}
        >
          {siteDetails?.name}
        </Typography>
        <SiteMenuList />
      </Stack>
      <Card
        sx={{
          width: "100%",
          marginRight: "20px",
          height: "calc(100vh - 199px)",
          boxShadow: "none",
        }}
      >
        <CardContent
          sx={{ padding: "0px !important", height: "100%", width: "100%" }}
        >
          <Routes>
            <Route path="/energyassets" element={<EnergyAssets />} />
            <Route path="/energyassets/createAsset" element={<CreateAsset />} />
            <Route path="/energyassets/editAsset/:assetId" element={<CreateAsset />} />
            <Route
              path="/sitedetails"
              element={
                <SiteDetails
                  siteDetails={siteDetails}
                  siteEdit={setIsSiteEdited}
                />
              }
            />
            <Route
              path="/asset-edgemapping"
              element={<AssetEdgeMapping siteId={siteId} />}
            />
            <Route
              path="/edgedevices/*"
              element={<EdgeDevices siteId={Number(siteId)} />}
            />
          </Routes>
        </CardContent>
      </Card>
    </Stack>
  );
};
