import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Button, ButtonGroup, Typography } from "@mui/material";
import { Asset } from "../../Models/models";
import localized from "../../en.json";
import { ArrowHeadRightIcon } from "../../theme/Icons/IshIcons";

const RenderButton = (
  name: string,
  value: number,
  backgroundColor: string,
  color: string,
  isAlarmWarning: boolean
) => {
  return (
    <Button
      sx={{
        padding: "12px 24px",
        background: backgroundColor + " !important",
        ":focus, :active, :enabled": { background: "#FFFFFF" },
        borderRadius: "8px",
      }}
    >
      <Box display="flex" flexDirection="column" alignItems="flex-start">
        {isAlarmWarning ? (
          <Box display="flex" alignItems="center">
            <ErrorOutlineIcon
              sx={{ width: "20px", height: "20px", color: color, mb: "4px" }}
            />
            <Typography
              variant="body2"
              sx={{ color: "#0D0D0D", fontSize: "22px", lineHeight: "30px" }}
            >
              {value}
            </Typography>
          </Box>
        ) : (
          <Typography
            variant="body2"
            sx={{ color: "#0D0D0D", fontSize: "22px", lineHeight: "30px" }}
          >
            {value}
          </Typography>
        )}
        <Box display="flex" sx={{ alignItems: "center" }}>
          <Typography
            variant="body1"
            sx={{ color: "#393939", textTransform: "none", fontSize: "16px" }}
          >
            {name}
          </Typography>
          <ArrowHeadRightIcon sx={{ width: "16px", height: "16px" }} />
        </Box>
      </Box>
    </Button>
  );
};

type Props = {
  assets: Asset[];
};
const EnergyAssetsSummary = (props: Props) => {
  const { assets } = props;
  const getProjectSize = () => {
    return 1;
    // const projectIds = new Set();

    // assets.forEach((asset) => {
    //   projectIds.add(asset.site.project.id);
    // });
    // return projectIds.size;
  };
  const getAlarmsAndWarningsCount = () => {
    let totalAlarms = 0;
    let totalWarnings = 0;

    // Iterate over the array and sum up alarms and warnings
    assets.forEach((asset) => {
      totalAlarms += asset.alarm;
      totalWarnings += asset.warning;
    });
    return {
      totalAlarms,
      totalWarnings,
    };
  };

  return (
    <ButtonGroup
      variant="contained"
      aria-label="Basic button group"
      sx={{
        ".MuiButtonGroup-grouped:not(:last-of-type)": {
          borderColor: "#EAEAEA",
          borderRight: "1px solid #EAEAEA",
        },
        border: "1px solid #EAEAEA",
        borderRadius: "8px",
        boxShadow: "none",
      }}
    >
      {RenderButton(
        localized["total-assets"],
        assets.length,
        "#FFFFFF",
        "",
        false
      )}
      {RenderButton(
        localized["projects"],
        getProjectSize(),
        "#FFFFFF",
        "",
        false
      )}
      {RenderButton(
        localized["alarms"],
        getAlarmsAndWarningsCount().totalAlarms,
        "rgba(218, 30, 40, 0.10)",
        "#DA1E28",
        true
      )}
      {RenderButton(
        localized["warnings"],
        getAlarmsAndWarningsCount().totalWarnings,
        "rgba(226, 208, 0, 0.10)",
        "#E2D000",
        true
      )}
    </ButtonGroup>
  );
};

export default EnergyAssetsSummary;
